import React, { useState, useContext } from "react";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdAccountBalanceWallet, MdOutlineAccountBalanceWallet, MdRouter } from "react-icons/md";

import { EnvTypeContext } from "../../context/EnvTypeContext";
import { getOrgRoles, getOrgName } from "../App/useToken";
import { SettingsIcon, AddIcon } from "@chakra-ui/icons";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { ReactComponent as CounterPartyStatusIcon } from "../../icons/counterParty.svg";
export default function Admin() {
  const [loading, setLoading] = useState(false);

  const { env } = useContext(EnvTypeContext);
  const orgRoles = getOrgRoles();
  const orgName = getOrgName();

  const adminDashboards = [
    { dashboard: "Payment Methods", icon: MdAccountBalanceWallet, link: "/admin/payment-methods", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Payment Routes", icon: SettingsIcon, link: "/admin/payment-routes", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Pricing", icon: MdOutlineAccountBalanceWallet, link: "/admin/pricing", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Configurations", icon: SettingsIcon, link: "/admin/configs", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Payment Gateways", icon: MdAccountBalanceWallet, link: "/admin/payment-gateways", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Rules", icon: SettingsIcon, link: "/admin/rules", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Live Rates", icon: FaMoneyCheckAlt, link: "/admin/live-rates", visible: orgRoles.includes("transfi_admin") },
    { dashboard: "Counter Party Status", icon: CounterPartyStatusIcon, link: "/admin/counter-party-status", visible: orgRoles.includes("transfi_admin") },
    {
      dashboard: "Routing Master View",
      icon: MdRouter,
      link: "/admin/routing-master-view",
      visible: orgRoles.includes("transfi_admin"),
    },
  ];
  const allowableBinanceOrgs = ["binance", "binance_demo", "binance_qa", "binance_prod", "binance_africa", "openpayd"];

  const AdminDashboard = (data) => {
    const details = data?.data;
    return (
      <>
        {details?.visible && (
          <div
            className="balance-card-zoom"
            style={{
              width: "250px",
              padding: "15px 20px",
              boxShadow: "0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
              borderRadius: "4px",
              height: "130px",
            }}
          >
            <Link to={`${details.link}`}>
              <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                <div>
                  <h3 style={{ fontSize: "20px", fontWeight: "600" }}>{upperCase(details.dashboard)}</h3>
                </div>
                <div style={{ width: "70px", height: "70px", borderRadius: "50%", display: "flex", justifyContent: "center" }}>
                  <details.icon style={{ fontSize: "50px" }} />
                </div>
              </div>
            </Link>
          </div>
        )}
      </>
    );
  };

  const renderTransfiDashboards = () => {
    const transfiDashboards = Object.keys(adminDashboards).sort();

    return (
      <div style={{ display: "flex", justifyContent: "left", padding: "20px 10px", flexWrap: "wrap", gap: "4.45%" }}>
        {transfiDashboards.map((value) => {
          return (
            <div style={{ marginBottom: "40px" }}>
              <AdminDashboard data={adminDashboards[value]} />
            </div>
          );
        })}
      </div>
    );
  };

  return !loading ? (
    <>
      <Box w="100%" p={4}>
        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "20px", padding: "0 10px", marginBottom: "5px" }}>
          <Text fontWeight={500}>Admin Dashboards</Text>
        </div>
        <hr style={{ margin: "0 8px", marginBottom: "16px" }} />
        {!allowableBinanceOrgs.includes(orgName) && renderTransfiDashboards()}
      </Box>
    </>
  ) : (
    <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
  );
}
