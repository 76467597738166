import React, { useState, useRef } from "react";
import Loader from "react-js-loader";
import { Box, Button, useDisclosure, Badge } from "@chakra-ui/react";
import { MenuButton, MenuList, MenuItem, Menu } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import TimezoneFormatter from "../../utils/TimezoneFormatter";

import { refundStatusMap, refundColorMap } from "../../utils/config";

import { getOrgRoles, getCxRoles } from "../App/useToken";

import { SettingsIcon } from "@chakra-ui/icons";
import moment from "moment";
import RefundGrid from "./helper/RefundGrid";

export default function CryptoRefund({refundData, setRefundData}) {
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [viewDetails, setViewDetails] = useState("");
    const [inputData, setInputData] = useState({});
    const [formValues, setFormValues] = useState({});
    const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
    const defaultFilters = {
        dateRange: defaultDate,
    }
    const [filters, setFilters] = useState(defaultFilters);
    const [dateRange, setDateRange] = useState(defaultDate);

    const orgRoles = getOrgRoles();
    const showColumn = orgRoles.includes("transfi_admin");
    const cxRole = getCxRoles();
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenApproveModal,
        onOpen: onOpenApproveModal,
        onClose: onCloseApproveModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenFilterModal,
        onOpen: onOpenFilterModal,
        onClose: onCloseFilterModal,
    } = useDisclosure({ defaultIsOpen: false });

    const gridRef = useRef();

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const columns = [
        {
            Header: "Refund Id",
            accessor: "rfId",
            width: "190"
        },
        {
            Header: "Order Id TransFi",
            accessor: "orderId",
            width: "190"
        },
        {
            Header: "Order Id Customer",
            accessor: "customer.orderId",
            width: "200",
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Customer Name",
            accessor: "customer.formattedName",
            width: "120",
            show: showColumn,
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "User ID / Email",
            width: "120",
            Cell: ({ row }) => {
                if (row?.original?.email) {
                    return row?.original?.email
                }
                else {
                    return row?.original?.userId
                }
            },
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.orderDate} format={"MM/DD/YYYY"} />
            }, 
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.orderDate} format={'LT'} />
            },
        },
        {
            Header: "Crypto Payment Partner",
            accessor: "crypto.partner",
            width: "170",
            Cell: ({ value }) => startCase(value) || "Okx",
            show: orgRoles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto Payment Order ID",
            accessor: "crypto.orderId",
            width: "170",
            Cell: ({ value }) => value || "NA",
            show: orgRoles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto Currency",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker;
                }
            },
            color: "warning",
        },
        {
            Header: "Crypto Sold By The User",
            width: "150",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount);
                }
            },
            color: "warning",
        },
        {
            Header: "Gas Fees",
            Cell: ({ value }) => value || "N//A"
        },
        {
            Header: "Crypto Conversion Rate USDC / Crypto",
            width: "190",
            Cell: ({ row }) => {
                if (row?.original?.crypto?.conversionRate) {
                    return numberFormatter(row?.original?.crypto?.conversionRate);
                } else {
                    return row?.original?.cryptoUnitPrice ? numberFormatter(row?.original?.cryptoUnitPrice) : "0.00";
                }
            },
            show: orgRoles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "USDC / USDT Purchased (Crypto Account)",
            accessor: "crypto.conversionAmount",
            width: "230",
            Cell: ({ value }) => numberFormatter(value) || "0.00",
            show: orgRoles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "USDC / USDT Purchased (Recalculated)",
            width: "230",
            Cell: ({ row }) => {
                const orderAmount = !isEmpty(row?.original?.crypto?.orderAmount) ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                const conversionRate = !isEmpty(row?.original?.crypto?.conversionRate) ? Number(row?.original?.crypto?.conversionRate || 0) : Number(row?.original?.cryptoUnitPrice || 0);
                return numberFormatter(orderAmount * conversionRate);
            },
            show: orgRoles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto Coversion Fees", 
            Cell: ({ value }) => value || "N//A",
            show: showColumn
        },
        {
            Header: "Crypto Wallet Address",
            width: "400",
            color: "warning",
            Cell: ({ row }) => {
                if (row?.original?.type === "sell") {
                    if (!isEmpty(row?.original?.metaData?.sendersWalletAddress)) {
                        return row?.original?.metaData?.sendersWalletAddress[0];
                    } else {
                        return "N/A";
                    }
                }
                else {
                    if (!isEmpty(row?.original?.walletAddress)) {
                        return row?.original?.walletAddress;
                    } else return "N/A";
                }

            }
        },
        {
            Header: "Crypto Payment Partner (Refund)",
            accessor: "cryptoRefund.paymentPartner",
            show: showColumn,
            width: "180",
            Cell: ({ value }) => startCase(value) || "-"
        },
        {
            Header: "Crypto Payment Partner Order Id (Refund)",
            accessor: "cryptoRefund.orderId",
            show: showColumn,
            width: "200",
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Refund Status",
            accessor: "status",
            Cell: ({ row }) => {
                const { status } = row?.original;
                const newStatus = refundStatusMap[status] || status;
                const color = refundColorMap[status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
            width: "180",
        },
        {
            Header: "Crypto Send to the User",
            show: showColumn,
            width: "200",
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Gas Fees (Refund)",
            Cell: ({ value }) => value || "N//A"
        },
        {
            Header: "Total Crypto Deducted from Wallet (Refund)",
            Cell: ({ value }) => value || "N//A"
        },
        {
            Header: "Refund Date",
            accessor: "refundDate",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.refundDate} format={"MM/DD/YYYY"} />
            },
        },
        {
            Header: "Refund gain/loss",
            show: showColumn,
            Cell: ({ row }) => {
                const cryptoAmount = Number(row?.original?.crypto?.orderAmount || 0);
                const buyRate = Number(get(row.original, "crypto.partnerFee", 0))
                let totalAmt = 0;
                if (row?.original?.crypto?.partnerFee) {
                    if (row?.original?.crypto?.partnerFee === 0 || row?.original?.crypto?.partnerFee === "NaN") {
                        totalAmt = cryptoAmount;
                    } else {
                        totalAmt = cryptoAmount - buyRate;
                    }
                } else {
                    totalAmt = cryptoAmount;
                }
                const partnerFees = Number(get(row.original, "cryptoRefund.partnerFees", 0))
                const refundedAmount = Number(get(row.original, "refundedAmount", 0))
                const totalAmount = partnerFees + refundedAmount;
                const gain = totalAmt - totalAmount;
                return numberFormatter(gain)
            },
        },
        {
            Header: "Notes",
            accessor: "description",
            show: showColumn,
            Cell: ({ value }) => value || "-"
        },
    ];

    if (orgRoles.includes("transfi_admin")) {
        columns.push({
            Header: "Actions",
            Cell: (cellProps) => {
                const { row = {}, data = [] } = cellProps;

                const { index } = row;
                const record = data?.[index];
                const status = row?.original?.status;
                return (
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} isDisabled={["initiated"].includes(status) ? false :true}>
                            <SettingsIcon />
                        </MenuButton>
                        <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        setSelectedRow(record);
                                        onOpenApproveModal();
                                    }}
                                >
                                    Approve
                                </MenuItem>
                        </MenuList>
                    </Menu>
                );
            },
        });
    }

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters(defaultFilters);
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }}>
            <Box p={4}>
                <Box display="flex" alignItems="center" mb={4}>
                    <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => setRefundData()}
                    >
                    Back
                    </Button>
                </Box>

                <RefundGrid 
                    columns={columns}
                    data={refundData}
                />
            </Box>
        </Box >
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
}